import { getImageUrl } from 'utilities/helpers';

/**
 * Get formatted activities - filtered by tour days and with images
 * @param activities
 * @param images
 * @param tourDays
 * @returns {Uint8Array}
 */
export const GET_FORMATTED_ACTIVITIES = (activities, images, tourDays) => { // eslint-disable-line
  return activities
    .map((act) => {
      // Save tour days against activity
      const BOOKING_TOUR_DAYS = tourDays.filter(day => act.day_ids.find(id => id === day.api_id));

      // if this activity has no matching tour days (for this booking tour) exclude
      if (!BOOKING_TOUR_DAYS.length) return null;

      // get activity image
      const IMAGE_URL = getImageUrl({
        id: act.picture_id,
        images,
        width: 280,
        height: 175,
      });

      return {
        ...act,
        booking_tour_days: BOOKING_TOUR_DAYS,
        // used for filtering
        booking_tour_day_numbers: BOOKING_TOUR_DAYS.map(day => day.ordinal_number),
        image_url: IMAGE_URL,
      };
    })
    // exclude invalid activities
    .filter(act => act);
};

export const GET_FORMATTED_ESSENTIALS = (activities, images, addons) => {// eslint-disable-line
  return activities
    .map((act) => {
      const BOOKING_ADDONS = addons.find(id => id === act.api_id);
      if (!BOOKING_ADDONS) return null;

      const IMAGE_URL = getImageUrl({
        id: act.sliding_first_picture_id,
        images,
        width: 280,
        height: 175,
      });

      return {
        ...act,
        image_url: IMAGE_URL,
      };
    })
    .filter(act => act);
};
